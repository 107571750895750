exports.components = {
  "component---node-modules-gatsbywpthemes-gatsby-theme-blog-data-src-templates-page-query-js": () => import("./../../../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/src/templates/page-query.js" /* webpackChunkName: "component---node-modules-gatsbywpthemes-gatsby-theme-blog-data-src-templates-page-query-js" */),
  "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-category-query-js": () => import("./../../../src/@gatsbywpthemes/gatsby-theme-blog-data/templates/category-query.js" /* webpackChunkName: "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-category-query-js" */),
  "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-post-query-js": () => import("./../../../src/@gatsbywpthemes/gatsby-theme-blog-data/templates/post-query.js" /* webpackChunkName: "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-post-query-js" */),
  "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-posts-query-js": () => import("./../../../src/@gatsbywpthemes/gatsby-theme-blog-data/templates/posts-query.js" /* webpackChunkName: "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-posts-query-js" */),
  "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-user-query-js": () => import("./../../../src/@gatsbywpthemes/gatsby-theme-blog-data/templates/user-query.js" /* webpackChunkName: "component---src-gatsbywpthemes-gatsby-theme-blog-data-templates-user-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

