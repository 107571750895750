module.exports = [{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-wp-search/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-wordpress-lightbox/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-plugin-gwpt-packages/gatsby-browser.js'),
      options: {"plugins":[],"wordPressUrl":"https://api.codinghelp.io","pathPrefix":"","paginationPrefix":"page","addWordPressComments":true,"addWordPressSearch":false,"gaTrackingId":"G-RX8PX1BNME","gaOptions":{"pluginConfig":{"head":true}},"googleTagManagerId":null,"googleTagManagerOptions":{},"addSiteMap":true,"siteMapOptions":{},"widgetAreas":{"slideMenuWidgets":["Categories","RecentPosts","Tags","SocialFollow"],"sidebarWidgets":["Categories","RecentPosts","Tags","SocialFollow"]},"archiveSidebarPosition":"right","pageCreateDebugOutput":false,"logo":"./static/assets/logo.png","darkModeLogo":null,"socialFollowLinks":[],"developLimit":null,"addColorModes":true,"webfontsOptions":{"fonts":{"google":[{"family":"Work Sans"},{"family":"Josefin Sans"}]}},"gatsbySourceWordPressOptions":{"production":{"allow404Images":true}},"createPages":true,"createPosts":true,"createCategories":true,"createTags":false,"createUsers":true,"withApollo":true,"layoutWidth":{"page":"lg","post":"full","archive":"full"},"fonts":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://api.codinghelp.io/graphql","excludeFieldNames":["blocksJSON","saveContent"],"presets":[{"presetName":"DEVELOP","options":{"type":{"__all":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true}}],"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RX8PX1BNME"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/@gatsbywpthemes/gatsby-theme-blog-data/gatsby-browser.js'),
      options: {"plugins":[],"layoutWidth":{"page":"lg","post":"full","archive":"full"},"logo":"./static/assets/logo.png","addSiteMap":true,"gaTrackingId":"G-RX8PX1BNME","gaOptions":{"pluginConfig":{"head":true}},"createTags":false,"webfontsOptions":{"fonts":{"google":[{"family":"Work Sans"},{"family":"Josefin Sans"}]}},"gatsbySourceWordPressOptions":{"production":{"allow404Images":true}},"wordPressUrl":"https://api.codinghelp.io","schema":{"timeout":1000000}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
